@import "~/node_modules/material-design-lite/material.min.css";
@import "~/node_modules/material-icons/iconfont/material-icons.css";
@import "~/node_modules/getmdl-select/getmdl-select.min.css";
@import "~/node_modules/typeface-roboto/index.css";
body {
  font-family: "Roboto", "Helvetica", sans-serif;
  font-weight: 400;
  background-color: #FAFAFA;
}

.mdl-layout__header {
  background-color: #19193d;
}

.container {
  width: 88.5%;
  margin: 0 auto 6em;
}

.mdl-dialog {
  width: 50%;
  max-width: 500px;
}
.mdl-dialog#login-dialog {
  width: 25em;
}

h1, h2 {
  font-family: "Roboto", "Helvetica", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

h1 {
  font-size: 2em;
}

h2, .mdl-dialog__title {
  font-size: 1.75em;
}

.scroll-table {
  max-height: calc(5 * 48px);
  overflow: auto;
}

.job-list {
  width: 100%;
}
.job-list.mdl-data-table thead {
  position: sticky;
  top: 0;
}

#s7-dir, #asset-list {
  font-family: monospace;
}

.asset-list-container {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin: 1em 0;
}

#asset-list {
  display: block;
  width: 100%;
  min-height: 300px;
  column-count: 2;
  background-color: #fff;
  padding: 1em;
  box-sizing: border-box;
}
#asset-list:focus {
  border: 0;
  outline: 0;
}

.job-delete, .run-link-copy {
  cursor: pointer;
}

.output-log {
  width: 100%;
  height: 10em;
  font-family: "Courier New", Courier, monospace;
}

.mdl-tabs__tab.fill-width {
  flex: 1;
}

.mdl-dialog__content {
  padding-top: 0;
}

.spinner-container {
  transform: scale(0.6) translateY(17%);
  display: inline-block;
}

.job-details-table {
  width: 100%;
}

.dt-label {
  font-size: 1.1em;
}

.create-job-switch {
  max-width: 17em;
  margin-bottom: 1em;
}

.dt-label {
  margin-bottom: 1em;
  display: inline-block;
}

#s7-options {
  padding-left: 4em;
}

.blur-scrim {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  backdrop-filter: blur(5px);
  z-index: 1;
  transition: height 0ms 0ms, backdrop-filter 250ms 0ms linear;
}
.blur-scrim.hide-scrim {
  height: 0;
  backdrop-filter: blur(0);
  transition: height 0ms 250ms, backdrop-filter 250ms 0ms linear;
}

dialog[open] {
  animation: showDialog 300ms ease-out;
}

@keyframes showDialog {
  from {
    transform: scale(1.3);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.px-hidden {
  width: 0;
  height: 0;
  position: absolute;
  outline: 0;
  border: 0;
}
.px-hidden:focus {
  outline: 0;
}

.verified-text {
  color: #00bc00;
}

.error-text {
  color: #f44336;
}